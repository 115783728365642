@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap");
.App {
  text-align: center;
  background: white;
  color: black;
  font-family: 'Source Sans Pro', sans-serif;
}

a:hover {
  cursor: pointer;
}

/* CONTACT */

.contact {
  text-align: center;
  background: rgb(255, 205, 205);
  padding: 2%;
}
.contact h1 {
  text-align: left;
}
.contact-info {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  box-sizing: border-box;
  color: black;
  margin: 1%;
  padding: 1%;
}

.contact-info h4 {
  margin: auto;
}

.operation {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

/* FOOTER */
.footer {
  background: black;
  padding: 3%;
}
.list {
  display: flex;
  justify-content: space-between;
}
.list a {
  text-decoration: none;
  color: white;
  font-size: larger;
  transition: 0.5s ease-in-out;
}
.list a:hover {
  color: black;
  transition: 0.5s ease-in-out;
}
#appointment-form {
  display: none;
}

/* APPOINTMENT REQUEST */
#booked {
  display: none;
}
.appointment-request {
  z-index: 0;
  background: white;
  position: fixed;
  display: inline-block;
  top: 94%;
  left: 70%;
  border-radius: 8.5px;
}
.index-form div {
  overflow: hidden;
}
.index-form form {
  background: white;
  padding: 0 10px;
  border: solid 1px black;
  position: absolute;
  width: 400px;
  bottom: 46px;
  left: -109px;
  text-align: left;
}
.names, .selection, .patient-contact, .patient-information {
  display: flex;
  justify-content: flex-start;
}
.middle-initial {
  width: 20px;
}
.index-form form div {
  margin: 5px;
}
.description {
  resize: none;
  margin: 5px 0;
  width: 90%;
  height: 36px;
}
.days {
  resize: none;
  margin: 5px 0;
  width: 240px;
  height: 14px;
}
.submit-button {
  text-align: center;
}
.g-recaptcha {
  background: #E8374D;
  border: 1px solid rgba(0, 0, 0, 0.507);
  box-shadow: 1px solid black;
  font-size: 100%;
  font-weight: bolder;
  text-shadow: 1px 1px black;
  border-radius: 8px;
  padding: 10px;
  color: white;
  font-family: 'Source Sans Pro', sans-serif;
  transition-duration: 1s;
}
.g-recaptcha:hover {
  background: black;
  transition-duration: 1s;
  cursor: pointer;
}

/* APPOINTMENT FORM */
.appointment-form {
  padding: 5%;
  background-color: white;
}

@media screen and (max-width: 900px) {
  /* APPOINTMENT REQUEST */
  .appointment-request {
    display: none;
  }

  /* APPOINTMENT FORM */
  .appointment-form input, .appointment-form select, .appointment-form textarea {
    border-radius: 4px;
    border: solid gray 1px;
  }
  .appointment-form p {
    text-align: left;
  }
  .appointment-form form div {
    margin: 10px;
  }
  .names, .patient-contact, .selection, .patient-information {
    text-align: left;
  }
  .patient-information textarea {
    resize: none;
    margin: 10px 0;
    width: 100%;
    height: 100px;
  }

  /* FOOTER */
  .footer {
    font-size: 40%;
  }
  #appointment-form {
    display: block;
  }
}
@media screen and (max-width: 760px) {
  .appointment-form p {
    text-align: left;
  }
  .appointment-form form div {
    margin: 10px;
  }
  .names, .patient-contact, .selection, .patient-information {
    display: block;
    text-align: left;
  }
  .patient-information textarea {
    resize: none;
    margin: 10px 0;
    width: 250px;
    height: 100px;
  }
}