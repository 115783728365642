#navbar {
    z-index: 2;
    background-color: rgb(53, 53, 53);
    display: grid;
    grid-template-columns: repeat(2, auto);
    height: 75px;
    padding: 0 3%;
}

.navbar {
  position: relative;
}

.sticky {
    position: sticky;
    top: 0;
  }

.logo img {
  height: 65px;
  margin: 5px;
  float: left;
}

.bar {
  display: grid;
  grid-template-columns: repeat(6, auto);
  align-items: center;
  align-content: center;
  float: right;
}

.bar a {
  text-decoration: none;
  color: white;
  font-size: 18px;
  transition-duration: 0.2s;
}

.bar a:hover {
  color: #E8374D;
  transition-duration: 0.2s;
}

.sub-menu {
    height: 75px;
    transition-duration: 0.5s;
}

/* .navbar .facebook {
  margin-right: 20px;
} */

.facebook img {
  width: 32px;
}

.navbutton {
  background-color: rgb(53, 53, 53);
  color: white;
  border: none;
  border-radius: 6px;
  height: 55px;
  font-size: 18px;
  font-weight: 400;
  margin-top: 10px;
  transition-duration: 0.3s;
  padding: 10px;
}

.hovered {
    background-color: white;
    color: rgb(53, 53, 53);
    border: none;
    border-radius: 6px;
    height: 55px;
    font-size: 18px;
    font-weight: 400;
    margin-top: 10px;
    padding: 10px;
    transition-duration: 0.3s;
    text-shadow: none;
}

.dropdown-menu {
  background: rgb(245, 245, 245);
  display: grid;
  overflow: hidden;
  position: absolute;
  top: 0px;
  transform: translate(5px, 75px);
  transition-duration: .4s;
  backdrop-filter: blur(16px);
}

.collapse-menu {
  position: absolute;
  width: 107.42px;
  top: 40px;
  transform: translate(5px, 0px);
  transition: 3s ease-in-out;
}

.dropdown-menu a {
  color: black;
  text-align: left;
  padding: 10px 20px 10px 10px;
  margin: 0;
  transition-duration: 0.2s ease-in-out;
}

.dropdown-menu a:hover {
  background: rgb(255, 255, 255);
  transition-duration: 0.2s ease-in-out;
}

.staff-menu {
  background: rgb(245, 245, 245);
  display: grid;
  overflow: hidden;
  position: absolute;
  top: -45px;
  transform: translate(5px, 120px);
  transition: .4s;
}

.staff-menu .staff-link a {
  display: block;
  color: black;
  text-align: left;
  padding: 10px 20px 10px 10px;
  margin: 0;
  transition-duration: 0.2s ease-in-out;
}

.staff-link {
  display: block;
  text-align: left;
}

.staff-menu .staff-link a:hover {
  color: #E8374D;
  background: rgb(255, 255, 255);
  transition: 0.2s ease-in-out;
}


@media screen and (max-width: 900px) {
    .hamburger, .open-hamburger {
        border: 0;
        height: 40px;
        width: 40px;
        padding: 0.5rem;
        cursor: pointer;
        transition: background-color 0.2s ease-in-out;
      }
      
      .hamburger svg, .open-hamburger svg {
        background: none;
        height: 24px;
      }

    .hamburger {
        display: block;
        background-color: rgb(53, 53, 53);
        color: white;
    }

    .expanded {
        z-index: 2;
        position: absolute;
        top: 72px;
        left: 0;
        flex-direction: column;
        width: 100%;
        height: calc(100vh - 60px);
        background-color: white;
        border-top: 1px solid black;
        display: block;
    }
    .invisible {
        z-index: 2;
        position: absolute;
        top: 72px;
        left: 0;
        flex-direction: column;
        width: 100%;
        height: calc(100vh - 60px);
        background-color: white;
        border-top: 1px solid black;
        display: none;
    }

    .open-hamburger {
        display: block;
        background-color: white;
        color: rgb(53, 53, 53);
        border-radius: 6px;
    }
    .expanded a {
        color: black !important;
        font-size: 24px;
        text-align: center;
    }

    /* NAVBAR */
    .logo img {
      display: none;
    }

    #mobile-bar {
      z-index: 2;
      display: block;
      text-align: left;
      background-color: rgb(53, 53, 53);
      padding: 0 5%;
    }

    #mobile-bar a {
        text-decoration: none;
        color: white;
    }

    .full-bar {
      display: flex;
      justify-content: space-between;
      /* margin: auto 10px; */
      align-items: center;
    }
    .menu-toggle {
      margin: auto 0;
    }
    .container {
      cursor: pointer;
      margin: 10px 0;
    }
    .bar1, .bar2, .bar3 {
      width: 20px;
      height: 2px;
      background-color: white;
    }
    .mobile-bar a {
      text-decoration: none;
      color: white;
    }
    .mobile-bar a:hover {
      color: #E8374D;
      transition-duration: 0.5s;
    }
    .menu-toggle {
      display: block;
    }
    .navbar .facebook {
      margin: auto 5px;
      display: block;
      padding: 3px;
    }
}

@media screen and (max-width: 760px) {
  .navbar .facebook {
    margin: auto;
  }
  .facebook img {
    width: 14px;
  }
}